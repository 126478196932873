.ab-head {
  padding-top: 3rem;
  font-size: 3rem;
  font-weight: 500;
  font-family: fantasy;
  text-transform: uppercase;
}
.w-100 {
  border-radius: 10px;
}
.ab-sub {
  font-size: 16px;
  font-weight: 200;
  padding-left: 5rem;
  padding-right: 5rem;
  text-transform: uppercase;
}
.row-ab {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
  align-content: center;
  align-items: center;
}
.text-secondary {
  color: #6c757d !important;
  text-align: justify;
}

@media only screen and (max-width: 767px) {
  .ab-sub {
    font-size: 16px;
    font-weight: 200;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .p-4 {
    padding: 1rem !important;
  }
  .row-ab {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -0.5);
    margin-left: calc(var(--bs-gutter-x) * -0.5);
    align-content: center;
    align-items: center;
    padding: 0.5rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .col-md-6 {
    flex: 0 0 auto;
    width: 100%;
  }
}
