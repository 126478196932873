.slide-h {
  height: auto;
  background-color: #000000;
  position: static;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.aboutstory-section {
  background-color: #111517;
  padding: 3rem 6rem;
  height: 112vh;
  text-align: center;
  position: relative;
}

.aboutstory-div {
  margin: 0 auto;
}

.aboutstory-head {
  font-size: 2.5rem;
  margin-bottom: 25px;
  color: #ef0d33;
  font-weight: 800;
  text-transform: uppercase;
}
.aboutstory-subhead {
  font-size: 1.2em;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
}

.twodiv-aboutstory {
  display: flex;
  gap: 4rem;
  justify-content: center;
  flex-wrap: wrap;
}

.first-aboutstory,
.second-aboutstory {
  flex: 1;
  min-width: 280px;
  height: auto;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

.aboutstory-text {
  background-color: #111517;
  width: 100%;
  font-size: 16px;
  color: #bcbdbd;
  line-height: 1.8;
  font-weight: 600;
  text-align: justify;
  letter-spacing: 0px;
  font-family: "Open Sans", sans-serif;
}

.aboutstory-button {
  padding: 10px 10px 9px 7px;
  background: transparent;
  color: white;
  transition: 0.5s cubic-bezier(0.858, 0.01, 0.068, 0.99);
  display: inline-block;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-size: 12px;
  line-height: 1;
  border: 1px solid white;
  font-weight: 500;
  letter-spacing: 3px;
}

.aboutstory-button:hover {
  background-color: #e61919;
  color: white;
  border: none;
}

@media only screen and (max-width: 767px) {
  .aboutstory-section {
    padding: 1rem;
    height: auto;
  }
  .first-aboutstory, .second-aboutstory {
    flex: 1 1;
    min-width: 280px;
    height: auto;
     margin: 0px 0; 
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
}
  .aboutstory-head {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .aboutstory-subhead {
    font-size: 0.6em;
  }

  .aboutstory-text {
    font-size: 12px;
    line-height: 1.6;
  }

  .twodiv-aboutstory {
    display: flex;
    gap: 0rem;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
  }
  .aboutstory-button {
    top: 6%;
    left: 28%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .aboutstory-section {
    padding: 1rem;
    height: auto;
  }

  .twodiv-aboutstory {
    gap: 2rem;
  }

  .aboutstory-button {
    top: 31%;
    left: 42%;
  }
}

@-moz-document url-prefix() {
  .aboutstory-button {
    outline: 1px solid #007bff;
  }
}

/*------------------------gallery-section------------------*/

body {
  background: url(http://api.thumbr.it/whitenoise-361x370.png?background=08161cff&noise=626262&density=15&opacity=30)
    repeat;
  font-family: Arial, sans-serif;
}
.container-p {
  width: 100%;

  margin: 0 auto;
}
.product-section {
  padding-bottom: 2rem;
  padding-top: 2rem;
  background: #232526;
  background: -webkit-linear-gradient(to right, #414345, #232526);
  background: linear-gradient(to right, #414345, #232526);
}
.head-p {
  font-size: 4rem;
  font-weight: 800;
  color: red;
  font-family: sans-serif;
  letter-spacing: 2px;
}
.about-awardsbox {
  margin: 20px;
  color: black;
}
.grid2 {
  border-radius: 8px;
  background: url(http://api.thumbr.it/whitenoise-361x370.png?background=d6d6d6ff&noise=626262&density=18&opacity=36);
  overflow: hidden;
  width: 100%;
  max-width: 1920px;
  list-style: none;
  text-align: center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}
.grid2 figure {
  position: relative;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  margin: 0px;
  width: 270px;
  height: 270px;
  text-align: center;
}
.grid2 figure img {
  position: relative;
  display: block;
  margin: 0 auto;
}
.grid2 figure figcaption {
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.grid2 figure figcaption::before,
.grid2 figure figcaption::after {
  pointer-events: none;
}
.img-p {
  width: 100%;
  border-radius: 10px;
}
.grid2 figure figcaption,
.grid2 figure a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.grid2 figure a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}
.grid2 figure h2 {
  font-weight: 300;
}
.grid2 figure h2 span {
  font-weight: 800;
}
.grid2 figure h2,
.grid2 figure p {
  margin: 0;
}
.grid2 figure p {
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  letter-spacing: 1.2px;
  font-size: 70.5%;
  font-weight: bold;
  position: relative;
  bottom: 87%;
}

figure.effect-oscar {
  cursor: pointer;
}
figure.effect-oscar img {
  top: 0%;
}
figure.effect-oscar figcaption {
  -webkit-transition: all 0.75s;
  transition: all 0.75s;
  background: rgba(249, 249, 249, 0.508);
}
figure.effect-oscar figcaption::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 1px solid #e0e0e0;
  content: "";
}
figure.effect-oscar h2 {
  transform: scale(0);
  font-size: 3.438em;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}
figure.effect-oscar figcaption::before,
figure.effect-oscar p {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(0);
  transform: scale(0);
}
figure.effect-oscar:hover h2 {
  transform: scale(0);
  font-size: large;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
figure.effect-oscar:hover figcaption::before,
figure.effect-oscar:hover p {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
figure.effect-oscar:hover figcaption {
  background-color: rgba(240, 66, 66, 0.75);
  -webkit-transition: all 0.75s;
  transition: all 0.75s;
  transform: scale(1);
}
figure.effect-oscar:hover img {
  opacity: 0.1;
}
.button-product {
  padding: 10px 10px 9px 7px;
  background: #ef0d3300;
  color: white;
  transition: 0.5s cubic-bezier(0.858, 0.01, 0.068, 0.99);
  display: inline-block;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 3px;
  border: 1px solid white;
}
.button-product:hover {
  background-color: #ef1c1c;
  color: white;
  border: none;
}
.butt-div {
  display: flex;
  padding-top: 3rem;
  justify-content: center;
}

@media screen and (max-width: 41.5em) {
  .grid figure {
    width: 100%;
  }
}

#main h1 {
  text-transform: uppercase;
  font-size: 65px;
  line-height: 1;
  color: red;
  font-weight: 600;
  padding: 2rem;
  letter-spacing: 0px;
  text-align: center;
  font-family: "Oswald", sans-serif;
  margin-bottom: -0.5rem !important;
}
.service-main {
  padding-top: 2rem;
}
.cards-list {
  z-index: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: auto;
}
.card {
  margin: 30px auto;
  width: 300px;
  height: 260px;
  border-radius: 50px 0;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 30%);
  cursor: pointer;
  transition: 0.4s;
  justify-content: flex-start !important;
  background-color: #97a9bd;
}

.card_image {
  background: #fff;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-se {
  width: 4rem;
  height: auto;
}
.card_image i {
  margin: 17px;
  font-size: 37px;

  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.card_title .heading {
  font-size: 1rem;
  font-weight: 700;
  margin: auto;
  text-align: center;
  color: #fff;
  font-weight: 700;
}
.card_title p {
  margin: 10px auto;
  line-height: normal;
  text-align: center;
  color: #fff;
  font-size: 15px;
}
.cards-list .card:nth-child(1) {
  background-color: #061727;
}
.cards-list .card:nth-child(1):hover {
  background-color: #5e6e80;
}

.cards-list .card1 .card_image a i {
  color: #97a9bd;
}

.cards-list .card:nth-child(2) {
  background-color: #5d27f2;
}
.cards-list .card:nth-child(2):hover {
  background-color: #625682;
}
.cards-list .card2 .card_image a i {
  color: #a197bd;
}
.cards-list .card:nth-child(3) {
  background-color: #5d27f2;
}
.cards-list .card:nth-child(3):hover {
  background-color: #625682;
}
.cards-list .card2 .card_image a i {
  color: #a197bd;
}
.cards-list .card:nth-child(4) {
  background-color: #061727;
}
.cards-list .card:nth-child(4):hover {
  background-color: #5e6e80;
}

.cards-list .card1 .card_image a i {
  color: #97a9bd;
}
.cards-list .card:nth-child(5) {
  background-color: #5d27f2;
}
.cards-list .card:nth-child(5):hover {
  background-color: #625682;
}
.cards-list .card2 .card_image a i {
  color: #a197bd;
}
.cards-list .card:nth-child(6) {
  background-color: #061727;
}
.cards-list .card:nth-child(6):hover {
  background-color: #5e6e80;
}
.cards-list .card3 .card_image a i {
  color: #bda697;
}
.cards-list .card:nth-child(7) {
  background-color: #061727;
}
.cards-list .card:nth-child(7):hover {
  background-color: #5e6e80;
}
.cards-list .card3 .card_image a i {
  color: #bda697;
}
.cards-list .card:nth-child(8) {
  background-color: #5d27f2;
}
.cards-list .card:nth-child(8):hover {
  background-color: #625682;
}
.cards-list .card2 .card_image a i {
  color: #a197bd;
}
.btn-flip {
  opacity: 1;
  outline: 0;
  color: #fff;
  line-height: 40px;
  position: relative;
  text-align: center;
  letter-spacing: 1px;
  display: inline-block;
  text-decoration: none;
  font-family: "Open Sans";
  text-transform: uppercase;
}
.btn-flip:hover:after {
  opacity: 1;
  transform: translateY(0) rotateX(0);
}
.btn-flip:hover:before {
  opacity: 0;
  transform: translateY(50%) rotateX(90deg);
}
.btn-flip:after {
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  color: #ababb4;
  display: block;
  transition: 0.5s;
  position: absolute;
  background: #fcfcfc;
  content: attr(data-back);
  transform: translateY(-50%) rotateX(90deg);
}
.btn-flip:before {
  top: 0;
  left: 0;
  opacity: 1;
  color: #f9f9ff;
  display: block;
  padding: 0 30px;
  line-height: 40px;
  transition: 0.5s;
  position: relative;
  /* background: #fbfbfd; */
  content: attr(data-front);
  transform: translateY(0) rotateX(0);
  border: 1px solid #fbfbfb;
}
.btn-read {
  justify-content: center;
  align-content: center;
  display: flex;
  margin: 31px;
}
@media only screen and (max-width: 767px) {
  #main h1 {
    text-transform: uppercase;
    font-size: 42px;
    line-height: 1;
    color: red;
    font-weight: 600;
    padding: 2rem;
    letter-spacing: 0px;
    text-align: center;
    font-family: "Oswald", sans-serif;
    margin-bottom: -0.5rem !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}
