.slider-container {
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.slider-image,
.slider-content {
  width: 50%;
  height: 100%;
}

.slider-image {
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: opacity 0.5s ease;
}

.slider-image.fade-out {
  opacity: 0;
}

.slider-image.fade-in {
  opacity: 1;
}
.butt {
  color: #fff;
  font-size: 4rem;
}
.butt:hover {
  color: #ef0d33;
}
.slider-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: auto;
  flex-grow: 1;
}

.slider-content .text-sh {
  font-size: 3rem;
  margin-bottom: 10px;
  width: 96%;
  font-weight: 700;
  color: #ffffff;
}

.slider-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
  top: 13%;
  gap: 8rem;
}
.slider-buttons button {
  margin: 0 10px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #007bff;
  color: #fff;
  font-size: 1em;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.slider-buttons button:hover {
  background-color: #0056b3;
}

@media only screen and (max-width: 767px) {
  .slider-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .slider-content .text-sh {
    font-size: 1.5rem;
    margin-bottom: 10px;
    width: 96%;
    font-weight: 700;
    color: #ffffff;
  }
  .slider-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: opacity 0.5s ease;
  }
  .slider-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: auto;
    flex-grow: 1;
    width: 100%;
    padding: 1rem;
  }
  .slider-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    position: relative;
    top: 5%;
    gap: 4rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .slider-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .slider-image {
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: opacity 0.5s ease;
    width: 100%;
  }
  .slider-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: auto;
    flex-grow: 1;
    width: 100%;
  }
  .slider-content .text-sh {
    font-size: 2rem;
    margin-bottom: 10px;
    width: 96%;
    font-weight: 700;
    color: #ffffff;
  }
}
