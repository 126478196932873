@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
:root {
  --html-bg: #000;
  --body-bg: #f5f5f5;

  --text-mute: #0009;

  --menu-toggler-bg: #000;
  --menu-toggler-line-color: #fff;

  --nav-bg: #eee;
  --nav-link-color: #222;
  --menu-transition-duration: 1000ms;
  --menu-transition-timing: cubic-bezier(0.5, 0, 0.19, 0.98);
}
* {
  margin: 0;
  box-sizing: border-box;
}
html {
  font-family: "Space Grotesk", sans-serif;
  background: #fff;
}
html:after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);

  pointer-events: none;
  z-index: 3;
}

header {
  z-index: 1;
  background: #ffffff;
  display: flex;
  width: calc(100% - 0rem);
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  padding: 0.5rem 2rem;
}
header .branding {
  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.75rem;
  line-height: 0.6;
  text-decoration: none;
  font-weight: 900;
  color: #ffffff;
  transition: var(--menu-transition-duration) var(--menu-transition-timing);
  transform: translateX(0);
  width: 15rem;
}
header .branding svg {
  max-height: 4rem;
}
.menu-toggle {
  position: relative;
  z-index: 1;
  height: 5rem;
  width: 5rem;
  background: #0100a4;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  clip-path: polygon(
    0 0,
    0 32%,
    0 63%,
    0 100%,
    30% 100%,
    63% 100%,
    100% 100%,
    100% 66%,
    100% 29%,
    100% 0,
    67% 0,
    33% 0
  );
  transition: calc(var(--menu-transition-duration) * 0.5);
}
.menu-toggle_line {
  width: 1.875rem;
  height: 2px;
  display: block;
  background: var(--menu-toggler-line-color);
  margin: 3px;
}
header nav {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  height: 100vh;
  padding: 5rem 5rem;
  width: 100vw;
  display: flex;
  align-items: center;
  background: #fff0;
  transition: calc(var(--menu-transition-duration) * 0.5)
    var(--menu-transition-timing);
  pointer-events: none;
}
header nav:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #8d23bc;
  z-index: -1;
  transform: translateY(-100%);
  transition: calc(var(--menu-transition-duration) * 0.75)
    var(--menu-transition-timing);
  opacity: 0.1;
}
header nav:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: black;
  z-index: -3;
  transform: translateY(-100%);
  transition: calc(var(--menu-transition-duration) * 0.75)
    var(--menu-transition-timing);
  transition-delay: calc(var(--menu-transition-duration) * 0.5);
}
header nav ul {
  list-style: none;
  padding: 0;
  max-width: 1280px;
  width: 100%;
  margin: auto;
}
header nav ul li {
  transform: translatex(-5rem);
  opacity: 0;
  transition: var(--menu-transition-duration) var(--menu-transition-timing);
}
header nav ul li a {
  position: relative;
  color: rgb(255 255 255);
  -webkit-text-stroke: 0px rgb(141 35 188);
  font-size: 2.5rem;
  letter-spacing: 4px;
  font-weight: 700;
  text-decoration: none;

  transition: 350ms;
}
header nav ul li a:before {
  content: "";
  position: absolute;
  top: calc(100% - 0.35em);
  right: 0%;
  width: 0;
  top: 100%;
  height: 0.1em;
  background: #fdfdfd;
  transition: 350ms;
  z-index: -1;
  opacity: 0.3;
}
header nav ul li a:hover:before {
  width: 100%;
  left: 0%;
}
header nav ul li a:hover {
  color: #ef0d33;
}
#menu-toggle-checkbox:checked ~ nav .menu-item:nth-child(1) {
  transition-delay: calc(var(--menu-transition-duration) * 0.75 + 50ms);
}
#menu-toggle-checkbox:checked ~ nav .menu-item:nth-child(2) {
  transition-delay: calc(var(--menu-transition-duration) * 0.75 + 100ms);
}
#menu-toggle-checkbox:checked ~ nav .menu-item:nth-child(3) {
  transition-delay: calc(var(--menu-transition-duration) * 0.75 + 150ms);
}
#menu-toggle-checkbox:checked ~ nav .menu-item:nth-child(4) {
  transition-delay: calc(var(--menu-transition-duration) * 0.75 + 200ms);
}
#menu-toggle-checkbox:checked ~ nav .menu-item:nth-child(5) {
  transition-delay: calc(var(--menu-transition-duration) * 0.75 + 250ms);
}
#menu-toggle-checkbox:checked ~ nav .menu-item:nth-child(6) {
  transition-delay: calc(var(--menu-transition-duration) * 0.75 + 300ms);
}
#menu-toggle-checkbox:checked ~ nav .menu-item:nth-child(7) {
  transition-delay: calc(var(--menu-transition-duration) * 0.75 + 350ms);
}
#menu-toggle-checkbox:checked ~ nav .menu-item:nth-child(8) {
  transition-delay: calc(var(--menu-transition-duration) * 0.75 + 400ms);
}
#menu-toggle-checkbox:checked ~ a.branding {
  opacity: 0;
  transform: translateX(2rem);
}
#menu-toggle-checkbox:checked ~ nav {
  pointer-events: all;
  --menu-transition-duration: 800ms;
}
#menu-toggle-checkbox:checked ~ nav:before {
  transform: translateY(0%);
}
#menu-toggle-checkbox:checked ~ nav:after {
  transform: translateY(0%);
}
#menu-toggle-checkbox:checked ~ nav ul li {
  opacity: 1;
  transform: translatex(0rem);
}
#menu-toggle-checkbox:checked ~ .menu-toggle {
  clip-path: polygon(
    20% 0%,
    0% 20%,
    30% 50%,
    0% 80%,
    20% 100%,
    50% 70%,
    80% 100%,
    100% 80%,
    70% 50%,
    100% 20%,
    80% 0%,
    50% 30%
  );
  background: #000000;
  transform: rotate(90deg);
  transition: calc(var(--menu-transition-duration) * 1);
}
#menu-toggle-checkbox:checked ~ .menu-toggle .menu-toggle_line:nth-child(1) {
  margin: 0;
  margin-bottom: -5px;
  transform: rotate(45deg) scale(2);
}
#menu-toggle-checkbox:checked ~ .menu-toggle .menu-toggle_line:nth-child(2) {
  width: 0 !important;
}
#menu-toggle-checkbox:checked ~ .menu-toggle .menu-toggle_line:nth-child(3) {
  margin: 0;
  margin-top: -5px;
  transform: rotate(-45deg) scale(2);
}
.menu-toggle_line {
  transition: 0.3s;
}
#menu-toggle-checkbox {
  display: none;
}
section {
  padding: 0rem 0rem;
  z-index: 1;
  position: relative;
}
section .column {
  width: 48.5%;
}
section .container {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
section.hero {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: calc(100vh - 2rem);
}
section.hero h1 {
  font-size: clamp(2.2rem, 4vw, 3rem);
  font-weight: 700;
  color: transparent;
  -webkit-text-stroke: 1.5px #222c4488;
  text-shadow: 0.05em 0.05em coral;
  max-width: 500px;
  line-height: 1.2;
  margin-bottom: 1rem;
  transform: translatey(-50px);
  opacity: 0;
  animation: fade-down 1s forwards ease;
  animation-delay: 0s;
}
section.hero p {
  max-width: 500px;
  font-size: clamp(1rem, 2vw, 1.25rem);
  font-weight: 400;
  color: var(--text-mute);
  margin-bottom: 2rem;
  transform: translatey(-50px);
  opacity: 0;
  animation: fade-down 1.25s forwards ease;
  animation-delay: 0.5s;
}
section.hero .btn {
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  transition: 620ms cubic-bezier(0.5, 0, 0.19, 0.98);
  display: inline-block;
  transform: translatey(-50px);
  opacity: 0;
  animation: fade-down 1.5s forwards ease;
  animation-delay: 1s;
}
section.hero .btn-dark-outline {
  color: #000;
  text-transform: uppercase;
  border: 2px solid #222c44;
  transform: rotateZ(0deg);
}
section.hero .btn-dark-outline:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background: #222c44;
  z-index: -1;
  transition: 420ms cubic-bezier(0.5, 0, 0.19, 0.98);
}
section.hero .btn-dark-outline:hover {
  color: #fff;
}
section.hero .btn-dark-outline:hover:before {
  width: 100%;
  left: 0;
}
@keyframes fade-down {
  from {
    transform: translatey(-50px);
    opacity: 0;
  }
  to {
    transform: translatey(0px);
    opacity: 1;
  }
}

@media only screen and (max-width: 767px) {
  header .branding {
    position: relative;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.75rem;
    line-height: 0.6;
    text-decoration: none;
    font-weight: 900;
    color: #ffffff;
    transition: var(--menu-transition-duration) var(--menu-transition-timing);
    transform: translateX(0);
    width: 10rem;
  }
  header nav ul li a {
    position: relative;
    color: rgb(255 255 255);
    -webkit-text-stroke: 0px rgb(141 35 188);
    font-size: 1.5rem;
    letter-spacing: 4px;
    font-weight: 700;
    text-decoration: none;
    transition: 350ms;
  }
  .menu-toggle {
    position: relative;
    z-index: 1;
    height: 2rem;
    width: 2rem;
    background: #0100a4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    clip-path: polygon(
      0 0,
      0 32%,
      0 63%,
      0 100%,
      30% 100%,
      63% 100%,
      100% 100%,
      100% 66%,
      100% 29%,
      100% 0,
      67% 0,
      33% 0
    );
    transition: calc(var(--menu-transition-duration) * 0.5);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
}
