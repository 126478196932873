.container-c {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 3rem;
}
.container-c2 {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0rem;
  padding-bottom: 3rem;
}
.section-c {
  background: #232526;
  background: linear-gradient(to right, #414345, #232526);
  position: static;
  padding: 0rem;
}
.img-contact{
  width: 100%;
  height: 100vh;
  object-fit: cover;
  
}
.error-message{
  font-size: 11px;
  color: red;
  letter-spacing: 1px;
  font-weight: 500;
}
.section-header-c {
  margin-bottom: 50px;
  text-align: center;
}
.logo-u {
  width: 2rem;
  height: auto;
}
.section-header-c .h2-b {
  text-transform: uppercase;
  font-size: 65px;
  line-height: 1;
  color: white;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
  font-family: "Oswald", sans-serif;
  margin-bottom: -0.5rem !important;
}

.section-header-c .p-b {
  width: 100%;
  font-size: 16px;
  color: #ffffff;
  line-height: 1.8;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0px;
  text-transform: uppercase;
  margin-top: 2rem;
  font-family: "Open Sans", sans-serif;
}

.row-c {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.contact-info-c {
  width: 50%;
}

.contact-info-item-c {
  display: flex;
  margin-bottom: 30px;
}

.contact-info-icon-c {
  height: 70px;
  width: 70px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(2px 4px 6px black);
}
.contact-info-icon-c i {
  font-size: 30px;
  line-height: 70px;
}

.contact-info-content-c {
  margin-left: 20px;
}

.contact-info-content-c .h4-b {
  color: #ef0d33;
  font-size: 1.4em;
  margin-bottom: 5px;
}

.contact-info-content-c .p-b {
  color: #fff;
  font-size: 1em;
}

.contact-form-c {
  background-color: #fff;
  padding: 40px;
  width: 45%;
  padding-bottom: 20px;
  padding-top: 20px;
  filter: drop-shadow(2px 4px 6px black);
  border-radius: 10px;
  
}

.contact-form-c .h2-b {
  font-weight: bold;
  font-size: 2em;
  margin-bottom: 10px;
  color: #333;
}

.contact-form-c .input-box-c {
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.contact-form-c .input-box-c input,
.contact-form-c .input-box-c textarea {
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  resize: none;
}

.contact-form-c .input-box-c span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  pointer-events: none;
  transition: 0.5s;
  color: #666;
}

.contact-form-c .input-box-c input:focus ~ span,
.contact-form-c .input-box-c textarea:focus ~ span {
  color: #e91e63;
  font-size: 12px;
  transform: translateY(-20px);
}

.contact-form-c .input-box-c input[type="submit"] {
  width: 100%;
  background: #ef0d33;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-weight: 700;
  font-size: 18px;
  border: 1px solid #00bcd4;
  transition: 0.5s;
}

.contact-form-c .input-box-c input[type="submit"]:hover {
  background: #5e0312;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .section-c {
    background: #232526;
    background: linear-gradient(to right, #414345, #232526);
    padding: 0rem;
  }
  .contact-info-c {
    width: 85%;
  }
  .img-contact {
    width: 100%;
    height: 48vh;
    object-fit: cover;
}.container-c2 {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0px; 
  padding-right: 0px; 
  padding-top: 0rem;
  padding-bottom: 2rem;
}
  .contact-info-icon-c {
    height: 70px;
    width: 70px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: none;
}
  .section-header-c .p-b {
    width: 100%;
    font-size: 14px;
    color: #ffffff;
    line-height: 1.8;
    font-weight: 600;
    padding: 0.5rem;
    text-align: center;
    letter-spacing: 0px;
    text-transform: uppercase;
    margin-top: 2rem;
    font-family: "Open Sans", sans-serif;
  }
  .container-c {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1px;
    padding-right: 1px;
  }
  .section-header-c .h2-b {
    text-transform: uppercase;
    font-size: 50px;
    line-height: 1;
    color: white;
    font-weight: 600;
    letter-spacing: 0px;
    text-align: center;
    font-family: "Oswald", sans-serif;
    margin-bottom: -0.5rem !important;
  }
  .row-c {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
  }
  .contact-form-c {
    background-color: #fff;
    padding: 15px;
    width: 96%;
    padding-bottom: 20px;
    padding-top: 20px;
    filter: drop-shadow(2px 4px 6px black);
    border-radius: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .container-c {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1px;
    padding-right: 1px;
  }
  .section-header-c .p-b {
    width: 100%;
    font-size: 16px;
    color: #ffffff;
    line-height: 1.8;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0px;
    text-transform: uppercase;
    margin-top: 2rem;
    font-family: "Open Sans", sans-serif;
    padding: 1rem;
}
.img-contact {
  object-fit: cover;
  width: 100%;
  height: 70vh;
}
  .contact-form-c {
    background-color: #fff;
    padding: 40px;
    width: 50%;
    padding-bottom: 20px;
    padding-top: 20px;
    filter: drop-shadow(2px 4px 6px black);
    border-radius: 10px;
  }
  .contact-info-c {
    width: 50%;
  }
}

.responsive-map {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.responsive-map iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
