/*------------------------services-section------------------*/

.services-section {
  padding-top: 35px;
  height: 100vh;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;
  z-index: -1;
}

.serve-head {
  text-transform: uppercase;
  font-size: 65px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
  font-family: "Oswald", sans-serif;
  margin-bottom: 0.5rem !important;
  margin-top: 3rem;
}
.serve-sub {
  font-size: 16px;
  font-weight: 200;
  text-align: center;
}
.services-container {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  gap: 3rem;
}

.first-serve,
.second-serve {
  flex: 1;
  margin: 10px;
}

.first-serve {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.first-head-serve {
  margin-bottom: 10px;
  color: #ef0d33;
  line-height: 1.8;
  font-weight: 600;
  text-align: justify;
  letter-spacing: 0px;
  font-family: "Open Sans", sans-serif;
}

.first-para-serve {
  width: 100%;
  font-size: 16px;
  color: #000000;
  line-height: 1.8;
  font-weight: 600;
  text-align: justify;
  letter-spacing: 0px;
  font-family: "Open Sans", sans-serif;
}

.first-button-serve {
  padding: 25px 60px 25px 40px;
  background: #1b2a2f;
  color: white;
  transition: 0.5s cubic-bezier(0.858, 0.01, 0.068, 0.99);
  display: inline-block;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 3px;
}
.first-button-serve:hover {
  background: #eb2731;
}

.second-serve {
  display: flex;

  justify-content: center;
}

.second-serve-img {
  width: 100%;
  height: 85%;
  object-fit: cover;
  filter: drop-shadow(2px 4px 6px black);
  border-radius: 10px;
}
@media only screen and (max-width: 767px) {
  .services-section {
    padding-top: 35px;
    height: 100vh;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .first-button-serve {
    padding: 16px 27px 16px 5px;
    background: #1b2a2f;
    color: white;
    transition: 0.5s cubic-bezier(0.858, 0.01, 0.068, 0.99);
    display: inline-block;
    text-transform: uppercase;
    font-family: "Oswald", sans-serif;
    font-size: 10px;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 3px;
  }
  .serve-head {
    text-transform: uppercase;
    font-size: 50px;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 0px;
    text-align: center;
    font-family: "Oswald", sans-serif;
    margin-bottom: -0.5rem !important;
  }
  .serve-sub {
    font-size: 12px;
    font-weight: 200;
    text-align: center;
  }
  .services-container {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    gap: 0rem;
    flex-direction: column;
  }
  .first-para-serve {
    width: 100%;
    font-size: 12px;
    color: #000000;
    line-height: 1.8;
    font-weight: 600;
    text-align: justify;
    letter-spacing: 0px;
    font-family: "Open Sans", sans-serif;
  }
  .alll {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .services-container {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    gap: 1rem;
    flex-direction: column;
  }
}
/*------------------common left image services-----*/
.services-section-c {
  height: 68vh;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;
  z-index: -1;
}

.services-container-c {
  display: flex;
  justify-content: space-between;

  gap: 3rem;
}

.first-serve-c,
.second-serve-c {
  flex: 1;
  margin: 10px;
}

.first-serve-c {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.first-head-serve-c {
  margin-bottom: 10px;
  color: #ef0d33;
  line-height: 1.8;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0px;
  font-family: "Open Sans", sans-serif;
}

.first-para-serve-c {
  width: 100%;
  font-size: 16px;
  color: #000000;
  line-height: 1.8;
  font-weight: 600;
  text-align: justify;
  letter-spacing: 0px;
  font-family: "Open Sans", sans-serif;
}

.first-button-serve-c {
  padding: 25px 60px 25px 40px;
  background: #1b2a2f;
  color: white;
  -webkit-transition: 0.5s cubic-bezier(0.858, 0.01, 0.068, 0.99);
  -o-transition: 0.5s cubic-bezier(0.858, 0.01, 0.068, 0.99);
  transition: 0.5s cubic-bezier(0.858, 0.01, 0.068, 0.99);
  display: inline-block;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 3px;
}
.first-button-serve-c:hover {
  background: #eb2731;
}

.second-serve-c {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.second-serve-img-c {
  width: 100%;
  height: 85%;
  object-fit: cover;
  filter: drop-shadow(2px 4px 6px black);
  border-radius: 10px;
}

@media only screen and (max-width: 767px) {
  .services-section-c {
    height: auto;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .services-container-c {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-direction: column;
  }
  .first-para-serve-c {
    width: 100%;
    font-size: 12px;
    color: #000000;
    line-height: 1.8;
    font-weight: 600;
    text-align: justify;
    letter-spacing: 0px;
    font-family: "Open Sans", sans-serif;
  }
  .first-button-serve-c {
    padding: 16px 27px 16px 5px;
    background: #1b2a2f;
    color: white;
    transition: 0.5s cubic-bezier(0.858, 0.01, 0.068, 0.99);
    display: inline-block;
    text-transform: uppercase;
    font-family: "Oswald", sans-serif;
    font-size: 10px;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 3px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .alll {
    margin-top: -21rem;
    margin-bottom: 24rem;
}
  .services-container-c {
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    flex-direction: column-reverse;
    margin-top: 21rem;
  }
  .services-section-c {
    height: 72vh;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

/*------------------common right image services-----*/
.services-section-r {
  height: 68vh;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;
  z-index: -1;
}

.services-container-r {
  display: flex;
  justify-content: space-between;

  gap: 3rem;
}

.first-serve-r,
.second-serve-r {
  flex: 1;
  margin: 10px;
}

.first-serve-r {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.first-head-serve-r {
  margin-bottom: 10px;
  color: #ef0d33;
  line-height: 1.8;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0px;
  font-family: "Open Sans", sans-serif;
}

.first-para-serve-r {
  width: 100%;
  font-size: 16px;
  color: #000000;
  line-height: 1.8;
  font-weight: 600;
  text-align: justify;
  letter-spacing: 0px;
  font-family: "Open Sans", sans-serif;
}

.first-button-serve-r {
  padding: 25px 60px 25px 40px;
  background: #1b2a2f;
  color: white;
  -webkit-transition: 0.5s cubic-bezier(0.858, 0.01, 0.068, 0.99);
  -o-transition: 0.5s cubic-bezier(0.858, 0.01, 0.068, 0.99);
  transition: 0.5s cubic-bezier(0.858, 0.01, 0.068, 0.99);
  display: inline-block;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 3px;
}
.first-button-serve-r:hover {
  background: #eb2731;
}

.second-serve-r {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.second-serve-img-r {
  width: 100%;
  height: 85%;
  object-fit: cover;
  filter: drop-shadow(2px 4px 6px black);
  border-radius: 10px;
}
@media only screen and (max-width: 767px) {
  .services-section-r {
    height: auto;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .services-container-r {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-direction: column;
  }
  .first-para-serve-r {
    width: 100%;
    font-size: 12px;
    color: #000000;
    line-height: 1.8;
    font-weight: 600;
    text-align: justify;
    letter-spacing: 0px;
    font-family: "Open Sans", sans-serif;
  }
  .first-button-serve-r {
    padding: 16px 27px 16px 5px;
    background: #1b2a2f;
    color: white;
    transition: 0.5s cubic-bezier(0.858, 0.01, 0.068, 0.99);
    display: inline-block;
    text-transform: uppercase;
    font-family: "Oswald", sans-serif;
    font-size: 10px;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 3px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .services-container-r {
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    flex-direction: column;
    margin-top: 23rem;
  }
  .services-section-r {
    height: 74vh;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
