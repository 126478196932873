* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container-n {
  padding-bottom: 2rem;
  margin: 0 auto;
  position: relative;
  
}

.head-n {
  height: 53px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 1px 0;
}
.text-head-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
}
.text-head-div1 {
  font-size: 4.5rem;
  font-weight: 700;
  text-transform: uppercase;
}
.text-head-div2 {
  font-size: 20px;
  width: 70%;
  text-align: center;
  letter-spacing: 4px;
  padding-top: 1.2rem;
}
.text-head-div-3 {
  font-size: 2.5rem;
  text-align: center;
  width: 90%;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.5;
  padding-top: 1rem;
}
.head-n h1 {
  text-transform: uppercase;
  font-size: 65px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0px;
  padding-bottom: 3rem;
  color: #ef0d33;
  text-align: center;
  font-family: "Oswald", sans-serif;
  margin-bottom: -0.5rem !important;
}
main {
  display: flex;
  gap: 2rem;
  flex-direction: row;
  flex-wrap: wrap;
}
.normal,
.hover {
  flex: 1;
  padding: 0 25px;
}

.demo-title {
  color: #666;
  margin: 0 0 15px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.module {
  min-width: 270px;
  height: 470px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease, height 0.3s ease;
  overflow: hidden;
  position: relative;
}

.module:hover {
  box-shadow: 0 1px 35px 0 rgba(0, 0, 0, 0.3);
}

.thumbnail {
  position: relative;
  overflow: hidden;
  background: black;
}

.thumbnail img {
  width: 100%;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.module:hover .thumbnail img {
  transform: scale(1.1);
  opacity: 0.6;
}

.thumbnail .date {
  position: absolute;
  top: 20px;
  right: 20px;
  background: #e74c3c;
  padding-top: 10px;
  color: #fff;
  font-weight: bold;
  border-radius: 100%;
  height: 55px;
  width: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
}

.date div:first-child {
  font-size: 18px;
  line-height: 1.2;
}

.content {
  position: absolute;
  width: 100%;
  height: 178px;
  bottom: 0;
  background: #fff;
  padding: 30px;
  transition: height 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05),
    opacity 0.3s ease;
}

.module:hover .content {
  height: 278px;
  opacity: 1;
}

.content .category {
  position: absolute;
  top: -34px;
  left: 0;
  color: #fff;
  text-transform: uppercase;
  background: #e74c3c;
  padding: 10px 15px;
  font-weight: bold;
}

.title {
  margin: 0;
  padding: 0 0 10px;
  color: #333333;
  font-size: 18px;
  font-weight: 700;
}

.sub-title {
  margin: 0;
  padding: 0 0 20px;
  color: #e74c3c;
  font-size: 13px;
  font-weight: 400;
}

.description {
  color: #666666;
  font-size: 14px;
  line-height: 1.2rem;
  height: 0;
  opacity: 0;
  transition: height 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05), opacity 0.3s ease;
  overflow: hidden;
}

.module:hover .description {
  height: 100px;
  opacity: 1;
}

.meta {
  margin: 30px 0 0;
  color: #999999;
}

@media only screen and (max-width: 767px) {
  main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
  .text-head-div1 {
    font-size: 2.5rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
}
.text-head-div-3 {
  font-size: 1.5rem;
  text-align: center;
  width: 91%;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.5;
  padding-top: 1rem;
}
.text-head-div2 {
  font-size: 16px;
  width: 70%;
  text-align: center;
  letter-spacing: 4px;
  padding-top: 1.2rem;
}
  .module {
    min-width: 270px;
    height: 450px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    transition: all 0.3s linear 0s;
    overflow: hidden;
    position: relative;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  main {
    display: flex;
    gap: 3rem;
    flex-direction: column;
    align-items: center;
  }
  .text-head-div1 {
    font-size: 4.5rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
}
}
