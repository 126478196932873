
    body{
    margin:0;
    overflow-x:hidden !important;
    box-sizing:border-box;
    font-family: 'Source Sans Pro', sans-serif;
    }
    .log-f {
        width: 150px;
        height: auto;
        background: aliceblue;
        border-radius: 20px;
        filter: drop-shadow(2px 4px 6px rgb(224, 28, 28));
        transition: transform 0.3s ease;
    }
    .log-f:hover{
transform: scale(1.2);
    }
    a {
        text-decoration: none;
        outline: none !important;
        color: #fff;
        font-size: 18px;
    }
    
    .border-shape {
    background: #ffbe00 none repeat scroll 0 0;
    color: #fff;
    display: block;
    height: 3px;
    left: 0;
    margin: 20px auto;
    position: relative;
    right: 0;
    text-align: center;
    top: 0;
    width: 80px;
    }
    
    .border-shape::before {
    background: #ff0612 none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 1px;
    left: 80px;
    margin: 0 auto;
    position: absolute;
    text-align: center;
    top: 1px;
    width: 100px;
    }
    
    .border-shape::after {
    background: #ff0000 none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 1px;
    margin: 0 auto;
    position: absolute;
    right: 80px;
    text-align: center;
    top: 1px;
    width: 100px;
    }
    
    .sec-c {
        display: flex;
        gap: 1rem;
        padding: 1rem;
        justify-content: center;
    }
    .logo-c{
        width: 1.5rem;
        height: auto;
        transition: transform 0.3s ease;
    }
    .logo-c:hover{
        color: #ff0000;
        transform: scale(1.5);
    }
    
    
    
  
    
    
    
    /*FOOTER*/
    footer{
    width:100%;
    background-color:#121619;
    color:#fff;
    }
    
    .top_header {
        padding: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }
    
    .top_header .sec{
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    }
    
    .top_header span {
        padding: 0 1rem;
        font-size: 17px;
        font-weight: 700;
    }
    
    .top_header .fa{
    color:#ffbe00;
    font-size:35px;
    }
    
    footer .border-shape {
    width: 40%;
    }
    
    footer .border-shape::before {
    width: 100%;
    left:120px;
    }
    
    footer .border-shape::after {
    width: 100%;
    right:120px;
    }
    footer .bottom_content .sec {
        padding: 0.5rem 2rem;
        display: flex;
        align-items: center;
        justify-content: Center;
    }
    
    .bottom_content a{
    margin:0 20px;
    color:rgba(255,255,255,0.7);
    transition:0.5s;
    }
    
    .bottom_content a:hover{
    color:rgba(255,255,255,1);
    }
    
    
    .copyright{
    padding:0.8em 0;
    background-color:#121619;
    text-align:center;
    color:rgba(255,255,255,0.7);
    font-size:12px;
    }
    
    
    
    @media (max-width:820px){
   
    
  
    footer .bottom_content .sec{
    padding:1rem;
    
    }
    footer .bottom_content .sec a{
    padding:1rem;
    font-size:12px;
    margin:0 5px;
    display:inline-block;
    }
    }
    .li-b{
        text-decoration: none;
        font-size: 12px;
    }
    .li-b:hover{
        color: #ffbe00;
    }
    
    @media only screen and (max-width: 767px){
        footer .border-shape::before {
            width: 100%;
            left: 95px;
        }
        .top_header {
            padding: 2rem;
            display: flex;
            gap: 0;
            position: relative;
            flex-direction: column;
            align-items: center;
        }
        .top_header .sec {
            margin: 15px 0;
            align-items: left;
            justify-content: left;
        }
        footer .bottom_content .sec {
            padding: 0.5rem 2rem;
            display: flex;
            align-items: center;
            justify-content: Center;
            flex-direction: row;
            flex-wrap: wrap;
        }
      }
      @media only screen and (min-width: 768px) and (max-width: 1023px){
        .top_header {
            padding: 1rem;
            display: flex;
        }
      }
       