.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-main {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 420px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transform: translateX(0);
  animation: zigzag 0.5s ease-in-out forwards;
  animation-play-state: paused;
}
@keyframes zigzag {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(10px) translateY(-10px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(-10px) translateY(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

.modal-close-button {
  position: absolute;
  top: -6px;
  right: 4px;
  background: none;
  border: none;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}
.modal-close-button:hover {
  color: rgb(35 75 209);
}

.display-block {
  display: flex;
}

.display-none {
  display: none;
}

.modal-img {
  width: 100%;
  height: auto;
}
.modal-text {
  font-size: 10px;
  font-weight: 200;
  margin: 0;
  padding: 10px;
  font-family: "Space Grotesk";
}

.modal-button {
  appearance: none;
  background-color: #ffffff;
  border-radius: 40em;
  border-style: none;
  box-shadow: #adcfff 0 -12px 6px inset;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: -0.24px;
  margin: 0;
  outline: none;
  padding: 1rem 1.3rem;
  quotes: auto;
  text-align: center;
  text-decoration: none;
  transition: all 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.modal-button:hover {
  background-color: #ffc229;
  box-shadow: #ff6314 0 -6px 8px inset;
  transform: scale(1.125);
}

.modal-button:active {
  transform: scale(1.025);
}

@media (min-width: 768px) {
  .modal-button {
    font-size: 1rem;
    padding: 0.75rem 2rem;
  }
}

@media only screen and (max-width: 767px) {
  .modal-main {
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
}
